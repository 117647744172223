import React from 'react'
import Chart from 'react-apexcharts'
import './Chart.css'

const SalesChart = () => {
  const data = {
    Expenses : 125000,
    sales : 52500,
  }

  const options = {
    labels : Object.keys(data),
    colors : ['#FF4560', '#008FFB'],
    legend : {
      position : "bottom",
    }
  }

  const series = Object.values(data)

  return (
    <div className='chart sales-chart'>
      <h3 className="chart-title">Expenses & Sales</h3>
      <Chart options={options} series={series} type="pie" height={350} />
    </div>
  )
}

export default SalesChart
