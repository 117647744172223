import React, { useState } from 'react'
import './Admin.css'
import Sidebar from '../../Components/Sidebar/Sidebar.jsx'
import Main from '../../Components/Container/Main.jsx'
import Header from '../../Components/Header/Header.jsx'
import Content from '../../Components/Container/Content.jsx'
import CardList from '../../Components/Card/CardList.jsx'
import ChartsContainer from '../../Components/Container/ChartsContainer.jsx'
import ActivityChart from '../../Components/Charts/ActivityChart.jsx'
import SalesChart from '../../Components/Charts/SalesChart.jsx'
import User from '../User/User.jsx'
import Shop from '../Shop/Shop.jsx'

const Admin = () => {
  const [activeTab, setActiveTab] = useState('dashboard');

  return (
    <div className="app">
      <Sidebar setActiveTab={setActiveTab} />
      <Main>
        <Header activeTab={activeTab} />
        <Content>
          {activeTab === 'dashboard' && (
            <>
              <CardList />
              <ChartsContainer>
                <ActivityChart />
                <SalesChart />
              </ChartsContainer>
            </>
          )}
          {activeTab === 'user' && <User />}
          {activeTab === 'shop' && <Shop />}
        </Content>
      </Main>
    </div>
  )
}

export default Admin
