import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Card.css";
import { Bounce, toast } from "react-toastify";

const UserCard = () => {
  const token = localStorage.getItem("token");
  // console.log('Retrieved Token:', token);

  const [users, setUsers] = useState([]);
  const [searchUser, setSearchUser] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [blockReason, setBlockReason] = useState("");

  const handleSearch = (e) => {
    e.preventDefault();
    const searchQuery = e.target.value.toLowerCase();
    const filteredResults = searchUser?.filter((user) => {
      if (user?.username?.toLowerCase().includes(searchQuery)) {
        return user;
      }
    });
    // console.log("filteredResults", filteredResults);
    setUsers(filteredResults);
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        console.log("1st step entered", token);
        const response = await axios.get(
          "https://api.spaceofmining.xyz/admin/users",
          {
            headers: {
              Authorization: token,
              "Content-Type": "application/json",
            },
          }
        );
        console.log("Fetched Users:", response.data);
        setUsers(response.data.users);
        setSearchUser(response.data.users);
      } catch (error) {
        console.error("Error fetching users:", error.message);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, [token]);

  // kaam ka code start
  const handleDeleteClick = (user) => {
    setSelectedUser(user);
    setConfirmationMessage(`Are you sure you want to delete ${user.email}?`);
    setShowDeleteModal(true);
  };

  const handleBlockToggleClick = (user) => {
    setSelectedUser(user);
    setConfirmationMessage(
      `Are you sure you want to ${user.blocked ? "unblock" : "block"} ${
        user.email
      }?`
    );
    setShowDeleteModal(false);
  };
  //  kaam ka code END

  const handleBlockModal = (user) => {
    setConfirmationMessage(
      `Are you sure you want to ${user.blocked ? "unblock" : "block"} ${
        user.email
      }?`
    );
    setSelectedUser(user);
  };

  const confirmBlockToggle = async () => {
    const user = selectedUser;
    const updatedUser = { ...user, blocked: !user.blocked };
    const url = `https://api.spaceofmining.xyz/admin/users/${user._id}/${
      user.blocked ? "unblock" : "block"
    }`;
    try {
      const response = await axios.put(
        url,
        { reason: blockReason },
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Block/Unblock response:", response.data);

      setUsers(users.map((u) => (u._id === user._id ? updatedUser : u)));
      if (response.data?.success) {
        toast(response.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      }
      setConfirmationMessage("");

      setBlockReason("");
    } catch (error) {
      console.error("Error updating user:", error);

      setConfirmationMessage("");
    } finally {
      setConfirmationMessage("");


      
    }
  };

  const deleteUser = async () => {
    const user = selectedUser;
    const url = `https://api.spaceofmining.xyz/admin/users/${user._id}`;
    try {
      const response = await axios.delete(url, {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      setUsers(users.filter((u) => u._id !== user._id));
      // setNotificationMessage(`User ${user.email} deleted successfully`);
      if (response.data?.success) {
        toast(response.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      }

      setConfirmationMessage("");
    } catch (error) {
      console.error("Error deleting user:", error.message);
      setConfirmationMessage("");
    } finally {
      setShowDeleteModal(false);
      setConfirmationMessage("");
    }
  };

  // const updateUser = async (userId, updatedUser) => {
  //   const url = `https://api.spaceofmining.xyz/admin/users/${userId}`;
  //   try {
  //     const response = await axios.put(url, updatedUser, {
  //       headers: {
  //         Authorization: token,
  //         "Content-Type": "application/json",
  //       },
  //     });
  //     setUsers(
  //       users.map((u) => (u._id === userId ? { ...u, ...updatedUser } : u))
  //     );
  //     setNotificationMessage(`User ${updatedUser.email} updated successfully`);

  //     console.log(response);
  //   } catch (error) {
  //     console.error("Error msg: ", error);
  //   }
  // };

  const closeModal = () => {
    setShowDeleteModal(false);
    setSelectedUser(null);
    setConfirmationMessage("");
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="user-table">
      <div className="input-box">
        <form className="d-flex gap-3">
          <input
            type="search"
            name="search-form"
            id="search-form"
            className="search-input py-2 px-4"
            onChange={(e) => handleSearch(e)}
            placeholder="Search user"
          />
          {/* <button type="submit" className="btn btn-primary">
            Search
          </button> */}
        </form>
      </div>
      <div className="filter-section">
        {/* <select>
          <option value="all">All</option>
        </select>
        <select>
          <option value="all-departments">All Departments</option>
        </select> */}
      </div>
      <table>
        <thead>
          <tr>
            <th>Serial No.</th>
            <th>UserName</th>
            <th>UserId</th>
            <th>Status</th>
            <th>Email id</th>
            <th>Balance</th>
            <th>Action</th>
            {/* <th>Edit Profile</th> */}
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user, index) => (
            <tr key={user.id}>
              <td>{index + 1}</td>
              <td>{user.username}</td>
              <td>
                {/* <FaUser size={15} /> */}
                {user._id}
              </td>
              <td>{user.blocked ? "Inactive" : "Active"}</td>
              <td>{user.email}</td>
              <td>{user.Inapptokens}</td>
              <td>
                {user.blocked ? (
                  <button
                    className={`block-button  unblock-button `}
                    onClick={() => handleBlockToggleClick(user)}
                  >
                    Unblock
                  </button>
                ) : (
                  <button
                    className={`block-button block-button`}
                    onClick={() => handleBlockModal(user)}
                  >
                    Block
                  </button>
                )}
              </td>
              {/* <td>
                <button
                  className="view-button"
                  onClick={() => handleViewClick(user)}
                >
                  Edit
                </button>
              </td> */}
              <td>
                <button
                  className="delete-button"
                  onClick={() => handleDeleteClick(user)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {confirmationMessage && (
        <div className="modal">
          <div className="modal-content">
            <h2>Confirm Action</h2>
            {/* <p>
              {showDeleteModal
                ? "delete"
                : selectedUser.blocked
                ? "unblock"
                : "block"}
            </p> */}
            <p>{confirmationMessage}</p>
            {!showDeleteModal ? (
              !selectedUser.blocked ? (
                <form>
                  <div className="form-floating">
                    <textarea
                      className="form-control"
                      placeholder="Leave a comment here"
                      id="floatingTextarea"
                      style={{ height: "100px" }}
                      onChange={(e) => setBlockReason(e.target.value)}
                      value={blockReason}
                    ></textarea>
                    <label for="floatingTextarea">Reason</label>
                  </div>
                </form>
              ) : null
            ) : null}

            <div className="d-flex justify-content-end gap-2 my-3 mx-1">
              {!showDeleteModal ? (
                selectedUser.blocked ? (
                  <button className="px-4 m-0" onClick={confirmBlockToggle}>
                    Yes
                  </button>
                ) : blockReason ? (
                  <button
                    className="px-4 m-0"
                    onClick={showDeleteModal ? deleteUser : confirmBlockToggle}
                  >
                    Yes
                  </button>
                ) : (
                  <button className="px-4 m-0 disabledBtn">Yes</button>
                )
              ) : (
                <button className="px-4 m-0" onClick={deleteUser}>
                  Yes
                </button>
              )}

              <button className="px-4 m-0" onClick={closeModal}>
                No
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserCard;
