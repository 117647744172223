import React, {useState} from 'react'
import AdminShop from './AdminShop';
import UserShop from './UserShop';

const Shop = () => {
  const [isAdmin, setIsAdmin] = useState(false);

  const handleToggle = () => {
    setIsAdmin(!isAdmin);
  };

  return (
    <div>
      <div>
        <button onClick={handleToggle} style={{padding: 10}}>
          {isAdmin ? 'Click to switch User Shop' : 'Click to switch Add Item'}
        </button>
      </div>
      {isAdmin ? <AdminShop /> : <UserShop />}
    </div>
  );
};

export default Shop