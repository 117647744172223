import React, { useState, useEffect } from "react";
import "./Shop.css";
import AddShopItem from "../../Components/AddUser/AddShopItem";
import axios from "axios";
import { Bounce, toast } from "react-toastify";

const AdminShop = () => {
  const token = localStorage.getItem("token");
  console.log("Retrieved Token:", token);

  const [shopItems, setShopItems] = useState([]);
  const [shopSearchItems, setShopSearchItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [formLoading, setFormLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [editForm, setEditForm] = useState({
    name: "",
    description: "",
    category: "",
    stock: "",
    price: "",
    image: null,
  });

  const handleSearch = (e) => {
    e.preventDefault();
    const searchQuery = e.target.value.toLowerCase();
    const filteredResults = shopSearchItems?.filter((item) => {
      if (item?.name?.toLowerCase().includes(searchQuery)) {
        return item;
      }
    });
    // console.log("filteredResults", filteredResults);
    setShopItems(filteredResults);
  };

  const categoryMange = ["Astronaut", "Miners", "Potions", "Gems"];
  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://api.spaceofmining.xyz/admin/shop-items",
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setShopItems(response.data?.products.reverse());
      setShopSearchItems(response.data.products);
      console.log("User shop details", response.data.products);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [token]);

  const handleViewClick = (user) => {
    setSelectedUser(user);
    setEditForm({
      name: user.name,
      description: user.description,
      category: user.category,
      stock: user.stock,
      price: user.price,
      image: null,
    });
    setShowEditModal(true);
  };

  const handleDeleteClick = (user) => {
    setSelectedUser(user);
    setShowDeleteModal(true);
  };

  const handleAddClick = () => {
    setShowAddModal(true);
  };

  const closeModal = () => {
    setShowEditModal(false);
    setShowDeleteModal(false);
    setShowAddModal(false);
    setSelectedUser(null);
  };

  const handleAddUser = async (formData) => {
    console.log("Adding new user:", formData);
    try {
      setFormLoading(true);
      const response = await axios.post(
        "https://api.spaceofmining.xyz/admin/add-shop-item",
        formData,
        {
          headers: {
            Authorization: token,
            "Content-Type": "multipart/form-data", // Ensure the content type is multipart/form-data
          },
        }
      );

      if (response.data.success) {
        fetchData();
        console.log("New user added successfully:", response.data.product);
        setShopItems([...shopItems, response.data.product]);
        closeModal();
        toast(response.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      }
      setFormLoading(false);
    } catch (error) {
      console.error("Error adding item:", error);
      setFormLoading(false);
    }
  };

  const handleSaveEdit = async () => {
    try {
      const formData = new FormData();
      formData.append("name", editForm.name);
      formData.append("description", editForm.description);
      formData.append("category", editForm.category);
      formData.append("stock", parseInt(editForm.stock, 10));
      formData.append("price", parseFloat(editForm.price));

      if (editForm.image) {
        formData.append("image", editForm.image);
      }

      const response = await axios.put(
        `https://api.spaceofmining.xyz/admin/edit-shop-item/${selectedUser._id}`,
        formData,
        {
          headers: {
            Authorization: token,
            "Content-Type": "multipart/form-data", // Ensure the content type is multipart/form-data
          },
        }
      );

      if (response.data.success) {
        const updatedItems = shopItems.map((item) =>
          item._id === selectedUser._id ? response.data.product : item
        );
        setShopItems(updatedItems);
        closeModal();
      }
    } catch (error) {
      console.error("Error updating item:", error);
    }
  };

  const handleDeleteConfirm = async () => {
    try {
      const response = await axios.delete(
        `https://api.spaceofmining.xyz/admin/delete-shop-item/${selectedUser._id}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      if (response.data.success) {
        setShopItems(shopItems.filter((item) => item._id !== selectedUser._id));
        closeModal();
      }
    } catch (error) {
      console.error("Error deleting item:", error);
    }
  };

  const handleEditFormChange = (e) => {
    const { name, value } = e.target;
    setEditForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
      setEditForm((prevState) => ({
        ...prevState,
        image: file,
      }));
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <>
      <div className=" d-flex justify-content-end">
        <button className="add-button m-0 my-4" onClick={handleAddClick}>
          Add Item
        </button>
      </div>

      <div className="user-table">
        <h2>ADD ITEM</h2>
        <div className="input-box">
          <form className="d-flex gap-3">
            <input
              type="search"
              name="search-form"
              id="search-form"
              className="search-input py-2 px-4"
              onChange={(e) => handleSearch(e)}
              placeholder="Search item"
            />
            {/* <button type="submit" className="btn btn-primary">
            Search
          </button> */}
          </form>
        </div>
        <div className="head">
          {/* <select>
          <option value="all">Sort</option>
          <option value="all">Date and Time</option>
        </select>
        <select className='filter'>
          <option value="all-departments">All Departments</option>
        </select> */}
        </div>
        <div className="filter-section"></div>
        <table>
          <thead>
            <tr>
              <th>Index</th>
              <th>Item Id</th>
              <th>Item Picture</th>
              <th>Item Name</th>
              <th>Item Description</th>
              <th>Category</th>
              <th>Stock Available </th>
              <th>Price</th>
              <th>Added By</th>
              <th>Edit Detail</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {shopItems.map((item, index) => (
              <tr key={item.id}>
                <td>{index + 1}</td>
                <td>{item._id}</td>
                <td>
                  {item?.image ? (
                    <img
                      src={`https://api.spaceofmining.xyz/uploads/${item?.image}`}
                      alt={item.name}
                      width="50"
                    />
                  ) : item?.image1 ? (
                    <img src={`${item?.image1}`} alt={item.name} width="50" />
                  ) : item?.image2 ? (
                    <img src={`${item?.image2}`} alt={item.name} width="50" />
                  ) : (
                    "No Image"
                  )}
                </td>
                <td>{item.name}</td>
                <td
                  style={{
                    maxWidth: "300px",
                    whiteSpace: "normal",
                    wordWrap: "break-word",
                  }}
                >
                  {item?.description}
                </td>
                <td>{item?.category}</td>
                <td>{item?.stock}</td>
                <td>{item?.price}</td>
                <td>{item?.addedBy}</td>
                <td>
                  <button
                    className="view-button"
                    onClick={() => handleViewClick(item)}
                  >
                    Edit
                  </button>
                </td>
                <td>
                  <button
                    className="delete-button"
                    onClick={() => handleDeleteClick(item)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {showEditModal && (
          <div className="modal">
            <div className="modal-content">
              <h2>Edit Item</h2>
              <label>
                Item Name:
                <input
                  type="text"
                  name="name"
                  value={editForm.name}
                  onChange={handleEditFormChange}
                />
              </label>
              <label>
                Item Description:
                <input
                  type="text"
                  name="description"
                  value={editForm.description}
                  onChange={handleEditFormChange}
                />
              </label>
              <label>
                Category:
                <select
                  name="category"
                  value={editForm.category}
                  onChange={handleEditFormChange}
                  className="full-width"
                >
                  {categoryMange.map((status) => (
                    <option key={status} value={status}>
                      {status}
                    </option>
                  ))}
                </select>
              </label>
              <label>
                Stock Available:
                <input
                  type="number"
                  name="stock"
                  value={editForm.stock}
                  onChange={handleEditFormChange}
                />
              </label>
              <label>
                Price:
                <input
                  type="text"
                  name="price"
                  value={editForm.price}
                  onChange={handleEditFormChange}
                />
              </label>
              <label>
                Image:
                <input
                  type="file"
                  accept="image/jpeg, image/png"
                  onChange={handleImageChange}
                />
              </label>
              <button onClick={handleSaveEdit}>Save</button>
              <button onClick={closeModal}>Cancel</button>
            </div>
          </div>
        )}

        {showDeleteModal && (
          <div className="modal">
            <div className="modal-content">
              <h2>Confirm Deletion</h2>
              <p>Are you sure you want to delete {selectedUser.name}?</p>
              <button onClick={handleDeleteConfirm}>Yes</button>
              <button onClick={closeModal}>No</button>
            </div>
          </div>
        )}

        {showAddModal && (
          <AddShopItem
            closeModal={closeModal}
            handleAddUser={handleAddUser}
            formLoading={formLoading}
          />
        )}
      </div>
    </>
  );
};

export default AdminShop;
