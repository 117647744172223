import React, { useEffect, useState } from "react";
import Card from "./Card";
import { IoCart, IoCash, IoPeople, IoPerson } from "react-icons/io5";
import axios from "axios";

const CardList = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [stats, setStats] = useState([]);
  const token = localStorage.getItem("token");
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        console.log("1st step entered", token);
        const response = await axios.get(
          "https://api.spaceofmining.xyz/admin/stats",
          {
            headers: {
              Authorization: token,
              "Content-Type": "application/json",
            },
          }
        );
        console.log("Fetched Users:", response.data?.stats);

        console.log(typeof `${stats?.totalUsers}`);
        setStats(response?.data?.stats);
      } catch (error) {
        console.error("Error fetching users:", error.message);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, [token]);

  const data = {
    Sales: {
      title: "Total Users",
      amount: `${stats?.totalUsers}`,
      growth: `${stats?.userChange}`,
      icon: <IoCart />,
      backgroundColor: "#FFE7E7",
    },
    Expenses: {
      title: "Total Items",
      amount: `${stats?.totalItems}`,
      growth: `${stats?.itemChange}`,
      icon: <IoCash />,
      backgroundColor: "#CAACA6",
    },
    Visitors: {
      title: "Total Visitors",
      amount: `${stats?.todayVisitors}`,
      growth: `${stats?.visitorChange}`,
      icon: <IoPeople />,
      backgroundColor: "#B47B84",
    },
    Orders: {
      title: "Total Orders",
      amount: `${stats?.todayOrders}`,
      growth: `${stats?.orderChange}`,
      icon: <IoPerson />,
      backgroundColor: "#944E63",
    },
  };
  return (
    <div className="card-list">
      {Object.keys(data).map((category, index) => (
        <Card key={index} data={data[category]} />
      ))}
    </div>
  );
};

export default CardList;
