import React from "react";
import "./Menulist.css";
import {
  IoDiamond,
  IoSettingsOutline,
  IoHelp,
  IoList,
  IoCodeWorking,
} from "react-icons/io5";

const Menulist = ({ setActiveTab }) => {
  const [activeItem, setActiveItem] = React.useState("dashboard");

  const handleItemClick = (tabName) => {
    setActiveItem(tabName);
    setActiveTab(tabName);
  };

  return (
    <ul className="menu-list">
      <li
        className={`menu-list-item ${
          activeItem === "dashboard" ? "active" : ""
        }`}
        onClick={() => handleItemClick("dashboard")}
      >
        <IoDiamond className="icon" />
        Dashboard
      </li>
      <li
        className={`menu-list-item ${activeItem === "user" ? "active" : ""}`}
        onClick={() => handleItemClick("user")}
      >
        <IoCodeWorking className="icon" />
        User
      </li>
      <li
        className={`menu-list-item ${activeItem === "shop" ? "active" : ""}`}
        onClick={() => handleItemClick("shop")}
      >
        <IoList className="icon" />
        Shop
      </li>
      {/* <li className={`menu-list-item ${activeItem === 'help' ? 'active' : ''}`} onClick={() => handleItemClick('help')}>
        <IoHelp className="icon" />Help
      </li>
      <li className={`menu-list-item ${activeItem === 'settings' ? 'active' : ''}`} onClick={() => handleItemClick('settings')}>
        <IoSettingsOutline className="icon" />Settings
      </li> */}
    </ul>
  );
};

export default Menulist;
