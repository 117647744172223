import React from 'react'
import './Container.css'
import { GoContainer } from 'react-icons/go'

const ChartsContainer = ({children}) => {
  return (
    <div className='charts-container'>{children}</div>
  )
}

export default ChartsContainer
