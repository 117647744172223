import React, { useState } from "react";
import "./AddShopItem.css";

const AddShopItem = ({ closeModal, handleAddUser, formLoading }) => {
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [category, setCategory] = useState("");
  const [stock, setStock] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [error, setError] = useState("");

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
      setImage(file);
      setError("");
    } else {
      setImage(null);
      setError("Please upload a valid JPG or PNG image.");
    }
  };
  console.log("image", image);
  const handleSubmit = async () => {
    if (!name || !price || !category || !stock || !description) {
      setError("All field are required.");
      return;
    }
    if (!image) {
      setError("Image is required!");
      return;
    }

    const formData = new FormData();
    formData.append("name", name);
    formData.append("price", parseFloat(price));
    formData.append("category", category);
    formData.append("description", description);
    formData.append("stock", parseInt(stock));
    formData.append("images", image);

    await handleAddUser(formData);
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <h2>Add Item</h2>
        {error && <div className="error-message">{error}</div>}
        <label>
          Name:
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </label>
        <label>
          Price:
          <input
            type="text"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          />
        </label>
        <label>
          Category:
          <select
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            className="full-width"
          >
            <option value="">Select Category</option>
            <option value="Astronauts">Astronauts</option>
            <option value="Miners">Miners</option>
            <option value="Potions">Potions</option>
            <option value="Gems">Gems</option>
          </select>
        </label>
        <label>
          Stock:
          <input
            type="text"
            value={stock}
            onChange={(e) => setStock(e.target.value)}
          />
        </label>
        <label>
          Description:
          <input
            type="text"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </label>
        <label>
          Images:
          <input
            type="file"
            accept="image/jpeg, image/png"
            onChange={handleImageChange}
          />
        </label>
        {formLoading ? (
          <button class="btn btn-primary" type="button" disabled>
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span className="ms-2">Loading...</span>
          </button>
        ) : (
          <button onClick={handleSubmit}>Add</button>
        )}
        <button onClick={closeModal}>Cancel</button>
      </div>
    </div>
  );
};

export default AddShopItem;
