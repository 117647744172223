import React from 'react'
import './Container.css'

const Main = ({ children }) => {
    return (
        <div className="main">{children}</div>
    )
}

export default Main
