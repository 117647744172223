import Login from "./Screens/Login/Login.jsx";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import "./App.css";
import Admin from "./Screens/Admin/Admin.jsx";
import User from "./Screens/User/User.jsx";
import Shop from "./Screens/Shop/Shop.jsx";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

// Route guard component
const ProtectedRoute = ({ element: Component, ...rest }) => {
  const token = localStorage.getItem("token");
  return token ? <Component {...rest} /> : <Navigate to="/" />;
};

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/admin" element={<ProtectedRoute element={Admin} />} />
        <Route path="/user" element={<User />} />
        <Route path="/shop" element={<Shop />} />
      </Routes>
      <ToastContainer />
    </>
  );
}

export default App;
