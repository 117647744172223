import React, { useState } from "react";
import "./Shop.css";
import img1 from "../../Assets/Images/Alka Rawat.jpeg";
import img2 from "../../Assets/Images/Ayan Pal - Speaker 12.jpg";

const UserShop = () => {
  const [users] = useState([
    {
      id: 1,
      name: "Andrew Bojangles",
      orderId: "2546891",
      itemName: "IceCream",
      dateTime: "15-04-24 08:00Am",
      orderStatus: "Shipped",
      paymentStatus: "Paid",
      paymentMethod: "Credit Card",
      totalOrder: "2",
      shippingAddress: "Sector 15, Noida",
      image: img1,
    },
    {
      id: 2,
      name: "Andrew Bojangles",
      orderId: "5469321",
      itemName: "MangoIce",
      dateTime: "12-05-24 06:00Am",
      orderStatus: "Pending",
      paymentStatus: "Unpaid",
      paymentMethod: "Bank Transfer",
      totalOrder: "3",
      shippingAddress: "Sector 52, Noida",
      image: img2,
    },
  ]);

  const [selectedUser, setSelectedUser] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const orderStatuses = [
    "Pending",
    "Shipped",
    "Delivered",
    "Cancelled",
    "Processing",
  ];
  const paymentStatuses = ["Paid", "Unpaid", "Refunded"];
  const paymentMethoded = [
    "Credit Card",
    "Paypal",
    "Bank Transfer",
    "UPI",
    "Cash",
  ];

  const handleViewClick = (user) => {
    setSelectedUser(user);
    setShowEditModal(true);
  };

  const handleDeleteClick = (user) => {
    setSelectedUser(user);
    setShowDeleteModal(true);
  };

  const closeModal = () => {
    setShowEditModal(false);
    setShowDeleteModal(false);
    setSelectedUser(null);
  };

  return (
    <div className="user-table">
      <h2>SHOP USER DETAIL</h2>
      <div className="head">
        {/* <select onChange={handleSortChange}>
          <option value="all">Sort</option>
          <option value="all">Date and Time</option>
        </select>
        <select className='filter'>
          <option value="all-departments">All Departments</option>
        </select> */}
      </div>
      <div className="filter-section"></div>
      <table>
        <thead>
          <tr>
            <th>User Name</th>
            <th>Order Id</th>
            <th>Item Name</th>
            <th>Date and Time</th>
            <th>Order Status</th>
            <th>Payment Status</th>
            <th>Payment Method</th>
            <th>Total Order</th>
            <th>Shipping Address</th>
            <th>Edit Detail</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user.id}>
              <td>
                <img src={user.image} alt={user.name} className="user-avatar" />
                {user.name}
              </td>
              <td>{user.orderId}</td>
              <td>{user.itemName}</td>
              <td>{user.dateTime}</td>
              <td>{user.orderStatus}</td>
              <td>{user.paymentStatus}</td>
              <td>{user.paymentMethod}</td>
              <td>{user.totalOrder}</td>
              <td>{user.shippingAddress}</td>
              <td>
                <button
                  className="view-button"
                  onClick={() => handleViewClick(user)}
                >
                  Edit
                </button>
              </td>
              <td>
                <button
                  className="delete-button"
                  onClick={() => handleDeleteClick(user)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {showEditModal && (
        <div className="modal">
          <div className="modal-content">
            <h2>Edit User</h2>
            <label>
              Name:
              <input type="text" value={selectedUser.name} />
            </label>
            <label>
              Order Status:
              <select
                value={selectedUser.orderStatus}
                onChange={(e) =>
                  setSelectedUser({
                    ...selectedUser,
                    orderStatus: e.target.value,
                  })
                }
                className="full-width"
              >
                {orderStatuses.map((status) => (
                  <option key={status} value={status}>
                    {status}
                  </option>
                ))}
              </select>
            </label>
            <label>
              Payment Status:
              <select
                value={selectedUser.paymentStatus}
                onChange={(e) =>
                  setSelectedUser({
                    ...selectedUser,
                    paymentStatus: e.target.value,
                  })
                }
                className="full-width"
              >
                {paymentStatuses.map((status) => (
                  <option key={status} value={status}>
                    {status}
                  </option>
                ))}
              </select>
            </label>
            <label>
              Payment Method
              <select
                value={selectedUser.paymentMethod}
                onChange={(e) =>
                  setSelectedUser({
                    ...selectedUser,
                    paymentMethod: e.target.value,
                  })
                }
                className="full-width"
              >
                {paymentMethoded.map((status) => (
                  <option key={status} value={status}>
                    {status}
                  </option>
                ))}
              </select>
            </label>
            <label>
              Total Order
              <input type="text" value={selectedUser.totalOrder} />
            </label>
            <label>
              Shipping Address
              <input type="text" value={selectedUser.shippingAddress} />
            </label>
            <button onClick={closeModal}>Save</button>
            <button onClick={closeModal}>Cancel</button>
          </div>
        </div>
      )}

      {showDeleteModal && (
        <div className="modal">
          <div className="modal-content">
            <h2>Confirm Deletion</h2>
            <p>Are you sure you want to delete {selectedUser.name}?</p>
            <button onClick={closeModal}>Yes</button>
            <button onClick={closeModal}>No</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserShop;
