import React from 'react'
import './Container.css'

const Content = ({ children }) => {
    return (
        <div className="content">{children}</div>
    )
}

export default Content
