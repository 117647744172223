import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Login.css";
import { FaUser, FaLock, FaEyeSlash, FaEye } from "react-icons/fa";

const Lgoin = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [apiError, setApiError] = useState("");
  const [showPw, setShowPw] = useState("password");
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(
        "https://api.spaceofmining.xyz/admin/login",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: username, password }),
        }
      );

      const data = await response.json();
      // Log the entire response to understand its structure
      console.log("Response:", data);

      if (response.ok) {
        // Store the token in local storage
        localStorage.setItem("token", data.accessToken);
        // Log the token
        console.log("Login Token:", data.accessToken);
        // Navigate to the admin page with the token
        navigate("/admin", { replace: true });
      } else {
        setApiError(data.message || "Login failed");
      }
    } catch (error) {
      setApiError("An error occurred. Please try again.");
    }
  };

  return (
    <div className="login-body">
      <div className="logo">spaceofmining</div>
      <div className="wrapper">
        <form action="" onSubmit={handleSubmit}>
          <h1>Login</h1>
          <div className="input-box">
            <input
              type="text"
              placeholder="Email"
              onChange={(e) => setUsername(e.target.value)}
              required
            />
            <FaUser className="icon" />
          </div>
          <div className="input-box">
            <input
              type={showPw}
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            {showPw == "password" ? (
              <FaEye className="icon" onClick={() => setShowPw("text")} />
            ) : (
              <FaEyeSlash
                className="icon"
                onClick={() => setShowPw("password")}
              />
            )}
          </div>
          {apiError && <p className="error">{apiError}</p>}
          <button type="submit">Login</button>
        </form>
      </div>
    </div>
  );
};

export default Lgoin;
