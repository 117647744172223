import React, { useState } from "react";
import "./Sidebar.css";
import Menulist from "../Menu/Menulist.jsx";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { IoLogOutOutline } from "react-icons/io5";

const Sidebar = ({ setActiveTab }) => {
  const [isOpen, setIsOpen] = useState();
  const handleLogout = () => {
    localStorage.clear();
    // localStorage.removeItem("token");
    window.location.reload();
  };
  const toggle = () => setIsOpen(!isOpen);
  return (
    <div className="sidebar-container">
      <div className="sidebar-toggle" onClick={toggle}>
        {isOpen ? <IoIosArrowBack /> : <IoIosArrowForward />}
      </div>
      <div className={isOpen ? "sidebar open" : "sidebar"}>
        <div className="sidebar-logo">Spaceofmining</div>
        <Menulist setActiveTab={setActiveTab} />
        <div className={`menu-list-item logoutBtn`} onClick={handleLogout}>
          <IoLogOutOutline className="icon " />
          Logout
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
