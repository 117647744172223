import React from "react";
import UserCard from "../../Components/Card/UserCard";

const User = () => {
  return (
    <div className="">
      <UserCard />
    </div>
  );
};

export default User;
