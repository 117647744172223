import React, { useState, useEffect } from "react";
import "./Header.css";
import { IoLogOutOutline, IoNotifications } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const Header = ({ activeTab }) => {
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const navigate = useNavigate();
  const [logoutTimer, setLogoutTimer] = useState(null);

  // Timeout duration in milliseconds (45 minutes)
  // const LOGOUT_TIMEOUT = 45 * 60 * 1000;

  // Reset timer function
  // const resetLogoutTimer = () => {
  //   if (logoutTimer) {
  //     clearTimeout(logoutTimer);
  //   }
  //   const timer = setTimeout(logoutUser, LOGOUT_TIMEOUT);
  //   setLogoutTimer(timer);
  // };

  // Logout function
  // const logoutUser = () => {
  //   localStorage.removeItem("token");
  //   navigate("/", { replace: true });
  // };

  // Start/reset timer on activity
  // const handleActivity = () => {
  //   resetLogoutTimer();
  // };

  // const handleLogout = () => {
  //   setShowLogoutModal(true);
  // };

  // const handleLogoutConfirm = () => {
  //   localStorage.removeItem("token");
  //   navigate("/", { replace: true });
  //   setShowLogoutModal(false);
  //   clearTimeout(logoutTimer); // Clear the timer when logging out manually
  // };

  // const closeModal = () => {
  //   setShowLogoutModal(false);
  // };

  const getTitle = () => {
    switch (activeTab) {
      case "dashboard":
        return "Dashboard";
      case "user":
        return "User";
      case "shop":
        return "Shop";
      case "help":
        return "Help";
      case "settings":
        return "Settings";
      default:
        return "Dashboard";
    }
  };

  // useEffect(() => {
  //   // Start the initial timer
  //   // resetLogoutTimer();

  //   // Event listeners for user activity
  //   const events = [
  //     "mousedown",
  //     "mousemove",
  //     "keypress",
  //     "scroll",
  //     "touchstart",
  //   ];
  //   const resetTimerOnUserActivity = () => handleActivity();

  //   events.forEach((event) => {
  //     document.addEventListener(event, resetTimerOnUserActivity, {
  //       passive: true,
  //     });
  //   });

  // Clean up event listeners
  //   return () => {
  //     if (logoutTimer) {
  //       clearTimeout(logoutTimer);
  //     }
  //     events.forEach((event) => {
  //       document.removeEventListener(event, resetTimerOnUserActivity);
  //     });
  //   };
  // }, [logoutTimer]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="header">
      <h2 className="header-title">{getTitle()}</h2>

      <div className="search-box">
        {/* <input type="text" placeholder='Search...' />
        <IoSearch className='search-icon'/> */}
      </div>

      <div className="user-box">
        {/* <IoNotifications className="user--icon" />
        <IoLogOutOutline className="user--icon" onClick={handleLogout} /> */}
      </div>

      {/* {showLogoutModal && (
        <div className="modal">
          <div className="modal-content">
            <h2>Confirm Logout</h2>
            <p>Are you sure you want to log out?</p>
            <button onClick={handleLogoutConfirm}>Yes</button>
            <button onClick={closeModal}>No</button>
          </div>
        </div>
      )} */}
    </div>
  );
};

export default Header;
