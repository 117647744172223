import React from 'react'
import './Chart.css'
import ReactApexChart from 'react-apexcharts'
import {motion} from 'framer-motion'

const ActivityChart = () => {

  const data = [
    { dollers: 40, sales: 20 },
    { dollers: 60, sales: 30 },
    { dollers: 80, sales: 40 },
    { dollers: 100, sales: 50 },
    { dollers: 120, sales: 60 },
    { dollers: 130, sales: 70 },
    { dollers: 150, sales: 80 },
    { dollers: 170, sales: 90 },
    { dollers: 200, sales: 100 },
    { dollers: 220, sales: 110 },
    { dollers: 240, sales: 120 },
    { dollers: 260, sales: 130 },
  ]

  const options = {
    chart: {
      height: 350,
      type: 'line',
    },

    xaxis: {
      categories: data.map((item) => `$${item.dollers}`),
      title: {
        text: "Dollers"
      }
    },

    yaxis: {
      title: {
        text: "Sales"
      }
    },

    dataLabels: {
      enabled: false,
    }
  };

  const series = [{
    name: 'Sales',
    data: data.map((item) => item.sales)
  }]

  return (
    <motion.div className='chart activity-chart' 
    initial={{opacity: 0, y : 100}} 
    animate={{opacity: 1, y : 0}} 
    transition={{duration: 2}}
    >
      <ReactApexChart options={options} series={series} type="area" height={350} colo/>
    </motion.div>
  )
}

export default ActivityChart
