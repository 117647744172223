import React from 'react'
import {motion} from 'framer-motion'
import './Card.css'
import CountUp from 'react-countup';

const Card = ({ data }) => {
    const { title, amount, growth, icon, backgroundColor } = data;

    const numericAmount = title === "Total Users" || title === "Total Items" || title === "Total Visitors" || title === 'Total Orders' ? parseFloat(amount.replace(/[$,]/g, "")) : amount;
    const displayDollarSign = title === "Total Sales" || title === "Total Expenses";

    return (
        <motion.div 
        className="card" 
        style={{backgroundColor}} 
        initial={{opacity: 0, scale: 0.7}} 
        animate={{opacity: 1, scale: 1}} 
        transition={{duration: 2}}
        >
            <div className="card--header">
                <div className="card--icon" style={{ color: backgroundColor}}>
                    {icon}
                </div>
                <div className="card--body">
                    <div className="amount"><CountUp start={0} end={numericAmount} /></div>
                    <div className="growth">
                        <p>{title}</p>
                        <span>{growth}</span>
                    </div>
                </div>
            </div>
        </motion.div>
    )
}

export default Card
